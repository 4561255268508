import "babel-polyfill";
import 'bootstrap/dist/css/bootstrap.css';



import videojs from 'video.js';
window.videojs = videojs;


import 'video.js/dist/video-js.css'
import './styles/main.scss'



//facebook
window.fbAsyncInit = function () {
    FB.init({
        appId: '1870833003183948',
        xfbml: true,
        version: 'v2.9'
    });
    FB.AppEvents.logPageView();
};

(function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
        return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "//connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));



var playerElement = document.getElementById("mainVideo");
if (playerElement !== null) {
    var player = videojs(playerElement);
    player.responsive(true)
    player.src({
        src: "https://seswa.bonce.tv/hls/bonceswa.m3u8",
        type: 'application/x-mpegURL'
    })





    player.ready(()=> {
        
            player.play().then(()=> {

            }).catch(err=> {
                console.log(err.message)
            })
    })


    // player.hlsQualitySelector();

} else {
    console.log("NOT !")

}

